import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SymmetryMdLibModule } from '@symmetry/md-lib';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { FormsModule } from '@angular/forms';
import { DashboardModule } from './dashboard/dashboard.module';
import { AuthModule } from './auth/auth.module';
import { EcomDashboardModule } from './ecom-dashboard/ecom-dashboard.module';
import { EcomDashboardModuleProd } from './ecom-dashboard prod/ecom-dashboard-prod.module';
import { EcomDashboardModuleUAT } from './ecom-dashboard uat/ecom-dashboard-uat.module';
import { EcomV2DevModule } from './ecom-v2-dev/ecom-v2-dev.module';
import { EcomV2UatModule } from './ecom-v2-uat/ecom-v2-uat.module';
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    DashboardModule,
    EcomDashboardModule,
    EcomV2DevModule,
    EcomV2UatModule,
    EcomDashboardModuleUAT,
    EcomDashboardModuleProd,
    AuthModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SymmetryMdLibModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatButtonToggleModule,
    FormsModule

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
