

export const environment = {
  production: false,
  api: {
    quicksight_cmis: 'https://dev.admin-portal.cmis.letha-local.co.za/clients/v1/quicksight/cmis/embedded/',
    quicksight_dev_ecom: 'https://dev.admin-portal.cmis.letha-local.co.za/clients/v1/quicksight/ecom/embedded/',
    quicksight_dev_ecom_v2: 'https://dev.admin-portal.cmis.letha-local.co.za/clients/v1/quicksight/ecom-v2/embedded/',
    quicksight_uat_ecom_v2: 'https://bw2y70t5g7.execute-api.eu-west-1.amazonaws.com/prod/analytics/v1/embedded-url/ecom/',
    quicksight_uat_ecom: 'https://uat.admin-portal.cmis.letha-local.co.za/clients/v1/quicksight/ecom/embedded/',
    quicksight_prod_ecom: 'https://admin-portal.cmis.letha-local.co.za/clients/v1/quicksight/ecom/embedded/'
  }
};


