import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';
import { AuthService } from '../auth/auth.service';

interface AdminUsers {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'sym-ecom-v2-uat',
  templateUrl: './ecom-v2-uat.component.html',
  styleUrls: ['./ecom-v2-uat.component.scss']
})
export class EcomV2UatComponent implements OnInit {
  url: any;
  show:boolean = false;
  initialFetch:boolean;
  email: any;
  dashboard: any;
  emailForm: FormGroup
  selectedValue: string;
  adminList: AdminUsers[] = [ 
    {value: 'CAN00157 - Daisy Diamonds', viewValue: 'info@letha-local.com'},
    {value: 'CAN00152 - MikesShop', viewValue: 'mike.love@symbiotics.co.za'},
    {value: "CAN00141 - eod art shop", viewValue: 'ernst.dettbarn@symbiotics.co.za'},
    {value: 'CAN00154 - My New Store', viewValue: 'nicky.nielsen@symbiotics.co.za'},
    {value: 'CAN00144 - Mike UAT', viewValue: 'mike.love@symbiotics.co.za'},
    {value: 'CAN00149 - Daisy Drndls', viewValue: 'nicky.nielsen@letha-local.com'}
  ];
  constructor(
    public auth: AuthService,
    public fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.emailForm = this.fb.group({
      userEmail: ['']
    });
    this.sortAdminUsersByCAN();
  }
  sortAdminUsersByCAN(){
    this.adminList.sort((a,b)=>(a.value > b.value) ? 1: -1)
    console.log(this.adminList)
  }
  loadEcomDashboardUatV2() {
    this.email = this.emailForm.controls.userEmail.value
    console.log('email', this.email)
    this.auth.getEcomDashboardUatV2(this.email).subscribe((response) => {
      this.url = response.payload
      console.log(this.url)
      var containerDiv = document.getElementById("ecomEmbeddingContainerUatV2");
      var options = {
        url: this.url,
        container: containerDiv,
        scrolling: "yes",
        height: "100%",
        width: "100%",
        footerPaddingEnabled: true
      };
      this.dashboard = QuickSightEmbedding.embedDashboard(options);
    }, err => {
    }
    );
  }

}
