import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';
import { AuthService } from '../auth/auth.service';

interface AdminUsers {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'sym-ecom-v2-dev',
  templateUrl: './ecom-v2-dev.component.html',
  styleUrls: ['./ecom-v2-dev.component.scss']
})
export class EcomV2DevComponent implements OnInit {
  url: any;
  show:boolean = false;
  initialFetch:boolean;
  email: any;
  dashboard: any;
  emailForm: FormGroup
  selectedValue: string;
  adminList: AdminUsers[] = [ 
    {value: 'CAN01482 - Mav-oct', viewValue: 'marius.venter@symbiotics.co.za'},
    {value: 'CAN01487 - Snack Shop', viewValue: 'netbal21@gmail.com'},
    {value: 'CAN01480 - HelloWorld', viewValue: 'bruno.pinheiro@symbiotics.co.za'},
    {value: 'CAN01486 - Eod Art', viewValue: 'ernst.dettbarn@symbiotics.co.za'},
    {value: "CAN01478 - Heels and boots", viewValue: 'kronos.gamer.rh@gmail.com'},
    {value: 'CAN01481 - ompaloompa', viewValue: 'masterpleb.gamer@gmail.com'},
    {value: 'CAN01459 - Mike2', viewValue: 'mike.love@symbiotics.co.za'}
  ];
  constructor(
    public auth: AuthService,
    public fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.emailForm = this.fb.group({
      userEmail: ['']
    });
    this.sortAdminUsersByCAN();
  }
  sortAdminUsersByCAN(){
    this.adminList.sort((a,b)=>(a.value > b.value) ? 1: -1)
    console.log(this.adminList)
  }
  loadEcomDashboardDevV2() {
    this.email = this.emailForm.controls.userEmail.value
    console.log('email', this.email)
    this.auth.getEcomDashboardDevV2(this.email).subscribe((response) => {
      this.url = response.payload
      console.log(this.url)
      var containerDiv = document.getElementById("ecomEmbeddingContainerDevV2");
      var options = {
        url: this.url,
        container: containerDiv,
        scrolling: "yes",
        height: "100%",
        width: "100%",
        footerPaddingEnabled: true
      };
      this.dashboard = QuickSightEmbedding.embedDashboard(options);
    }, err => {
    }
    );
  }


}
