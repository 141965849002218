import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'sym-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  currentUser = JSON.parse(localStorage.getItem('currentUser'));
  endPoint = environment.api.quicksight_cmis;
  imageSrc = '../assets/logos/letha.png';
  user: any;
  userName: any;
  userId: any;
  url = 'https://dev-cmis.auth.eu-west-1.amazoncognito.com/login?client_id=41ak98t3n69adoalv3k2jmrrfc&response_type=token&scope=openid&redirect_uri=https://dev.analytics.cmis.letha-local.co.za/dashboard'
  urlV2 = 'https://uat-cmis.auth.eu-west-1.amazoncognito.com/login?client_id=2403r1neikf0cpbivtfqsuanv2&response_type=token&scope=openid&redirect_uri=https://uat.analytics.cmis.letha-local.co.za/dashboard'
  constructor() {
    this.navigateToTestBed();
    this.navigateToTestBedEcomV2();
  }

  displayErrorState(status: string) {
    console.log(status + '  ERROR');
  }
  displayErrorMessage(status: string) {
    console.log(status + '  ERROR');
  }

  navigateToTestBed() {
    document.location.href = this.urlV2;
  }
  navigateToTestBedEcomV2() {
    document.location.href = this.url;
  }

}
