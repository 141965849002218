import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { retry, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  qsCmisEndpoint = environment.api.quicksight_cmis;
  qsEcomEndpointDev = environment.api.quicksight_dev_ecom;
 
  qsEcomEndpointUAT = environment.api.quicksight_uat_ecom;
  qsEcomEndpointProd = environment.api.quicksight_prod_ecom;
  qsEcomEndpointDevV2 = environment.api.quicksight_dev_ecom_v2;
  qsEcomEndpointUatV2 = environment.api.quicksight_uat_ecom_v2;

  constructor(private http: HttpClient) { }

  private REST_API_SERVER = '';

  /** Contains URL for redirect */
  redirectUrl: any;

  handleError(error) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.error.error}`;
    }
    return throwError(errorMessage);
  }

  sendEmailVerifyUserRequest(body) {
    return this.http.put<any>(this.REST_API_SERVER + '/user/confirm', {
      username: body.username,
      code: body.code,
    }, { observe: 'response' }).pipe(retry(3), catchError(this.handleError));
  }

  getCmisDashboard(email:any): Observable<any> {
    return this.http.get<any>(this.qsCmisEndpoint + email);
  }

  getEcomDashboardDev(email:any): Observable<any> {
    return this.http.get<any>(this.qsEcomEndpointDev + email);
  }
  
  getEcomDashboardUAT(email:any): Observable<any> {
    return this.http.get<any>(this.qsEcomEndpointUAT + email);
  }
  getEcomDashboardProd(email:any): Observable<any> {
    return this.http.get<any>(this.qsEcomEndpointProd + email);
  }

  getEcomDashboardDevV2(email:any): Observable<any> {
    return this.http.get<any>(this.qsEcomEndpointDevV2 + email);
  }

  getEcomDashboardUatV2(email:any): Observable<any> {
    return this.http.get<any>(this.qsEcomEndpointUatV2 + email);
  }
}
