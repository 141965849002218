import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EcomV2DevComponent } from './ecom-v2-dev.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BaseModule } from '../helpers/core/base/base.module';
import { MaterialModule } from '../helpers/core/material/material.module';
import { Routes } from '@angular/router';


const routes: Routes = [
  {
    path:'ecom-dashboard-v2',
    component: EcomV2DevComponent
  }
  ]
@NgModule({
  declarations: [EcomV2DevComponent],
  imports: [
    BaseModule,
    MaterialModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,
    MatTabsModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports:[EcomV2DevComponent]
})
export class EcomV2DevModule { }
