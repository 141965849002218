import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EcomDashboardComponentProd } from './ecom-dashboard prod/ecom-dashboard-prod.component';
import { EcomDashboardComponentUAT } from './ecom-dashboard uat/ecom-dashboard-uat.component';
import { EcomDashboardComponent } from './ecom-dashboard/ecom-dashboard.component';
import { EcomV2DevComponent } from './ecom-v2-dev/ecom-v2-dev.component';
import { EcomV2UatComponent } from './ecom-v2-uat/ecom-v2-uat.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    component: DashboardComponent
  },
  {
    path: 'ecom-dashboard',
    component: EcomDashboardComponent
  },
  {
    path: 'ecom-dashboard-v2',
    component: EcomV2DevComponent
  },
  {
    path: 'ecom-dashboard-v2-uat',
    component: EcomV2UatComponent
  },
  {
    path: 'ecom-dashboard-uat',
    component: EcomDashboardComponentUAT
  },
  {
    path: 'ecom-dashboard-prod',
    component: EcomDashboardComponentProd
  },
  {
    path: 'login',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
